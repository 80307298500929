import React from 'react'

const Comments = () => {
  return (
    <div className='p-2 m-2'>
        <h1 className='text-xl font-bold'>Comments :</h1>
    </div>
  )
}

export default Comments