import React from 'react'
import Button from './Button'
//const list =["All","Music","Cricket","Movies","Songs","Animals","Serials","Webseries","Animation","Live"]
const ButtonList = () => {
   
    return (
      <div className='flex'>
  
       <Button name="Live"/>
       <Button name="Music"/>
       <Button name="Movies"/>
       <Button name="Cricket"/>
          
      </div>
    )

  
  
  
}

export default ButtonList