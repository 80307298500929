import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {  useSearchParams} from 'react-router-dom';
import { closeMenu } from '../utils/appSlice';
import Comments from './Comments';
import LiveChat from './LiveChat';

const WatchPage = () => {
  const [searchParams]= useSearchParams();
  const dispatch =useDispatch();
  useEffect(()=>{
    dispatch(closeMenu());
  })
  return (
    <>
    <div className='flex flex-col px-7'>
      <div className='flex'>
        <div> 
        <iframe width="1100" height="550" 
        src={"https://www.youtube.com/embed/"+searchParams.get("v")}
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>

        </iframe>
    </div >
    <div className='ml-2 font-bold '>
    <LiveChat/>
   
    </div>

    </div>
    <Comments />
    </div>
    </>
  )
}

export default WatchPage