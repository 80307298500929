import React from 'react'

const Shimmer = () => {
  return (
    <div className='p-2 m-2 bg-gray-200 shadow-lg animate-pulse h-44 w-72'>
    
  
</div>
  )
}

export default Shimmer